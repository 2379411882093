// 该文档为VIP国际化-阿拉伯语
const zh = {
	// 提示信息
	tipsInfo:{
		noData:'لا يوجد بيانات',
		uploading:'جاري التحميل',
		uploadExcelTypeTips:'لا يقبل الا ملف اكسل',
		uploadSuccess:'تم التحميل بنجاح',
		uploadFailed:'فشل التحميل',
		loadingText:'جاري التحميل',
		generating:'قيد التنفيذ',
		exportSuccess:'تم التصدير بنجاح',
		downloadSuccess:'تم التنزيل',
		exportCancel:'الغاء التصدير',
		exportFailed:'فشل التصدير',
		downloadTips:'جاري التحميل، يرجى الانتظار',
		loginOutTips:'انتهت مهلة تسجيل الدخول، يرجى تسجيل الدخول مرة أخرى',
		errorPassword:'كلمة المرور خطاء، يرجى إعادة إدخالها',
		noPodInfo:'لا توجد معلومات POD حتى الآن',
		// 输入框或者选择框提示信息 与填写表单文字和输入选择框的placeholder结合使用
		inputPlaceholder:'يرجاء الادخال ',
		selectPlaceholder:'يرجاء الاختيار ',
		nullPlaceholder:'لا يمكن ان يكون فارغاً',
		inputTips:'يرجى ادخال المعلومات',
		
		orderNoExistTips:' رقم الطلب الذي تم إدخاله غير موجود',
		createOrderNoFailedTips:' تعذر الحصول على رقم الطلب',
		//删除
		deleteRowTips:'هل متأكد من انك تريد حذف بيانات الصف الحالي؟',
		deleteAllRowTips:'هل متاكد من حذف جميع المنتجات المذكورة أدناه',
		deleteSuccessTips:'تم الحذف',
		
		copyTips:'انسخ معلومات العمود الحالي',
		copySuccess:'تم النسخ ',
		copyFail:'فشل النسخ',
		
		submitOrderFBXFileTips:'يرجى تحميل ملف FBX أولاً ',
		submitOrderPDFFileTips:'يرجى تحميل ملف PDF أولا',
		submitOrderDeliveryFileTips:'يرجى تحميل ملف الملحقات الارسال إلى المستودع',
		submitOrderDeliveryMarkTips:'يرجى تحميل العلامة الملصقة على كرتون الشحن',
		submitOrderSkuListFileTips:'يرجى تحميل محتوى القائمة أولا ',
		submitOrderPacketFileTips:'يرجى تحميل المرفق الخاص بالطرد',
		
		selectOrderTips:'يرجى اختيار الطلب',
		selectCartonTips:'يرجى اختيار الصندوق ',
		importTips:'حدد الملف لاستيراد البيانات',
		openDialogTips:'فتح نافذة العمل',
		selectRowTips:'اختر جدول بيانات العمل',
		
		exportDatabySearch:'تصدير البيانات على أساس معايير البحث',
		uploadFileNameLimitTips:'上传文件名称过长，请重新上传',
		
		
		tip:'تلميح',
		warning:'تحذير',
		info:'معلومات',
		success:'نجاح',
		operationSuccess:'تم العملية بنجاح',
		failed:'فشل',
		
	},
	// 通用信息
	commonInfo:{
		languageZh:'اللغة الصينية',
		languageEn:'اللغة الإنجليزية',
		// 共用搜索条件按钮 
		searchBtn:'البحث',
		refreshBtn:'تحديث',
		createBtn:'جديد',
		addBtn:'إضافة',
		yesBtn:'نعم',
		noBtn:'لا',
		deleteBtn:'حذف',
		deleteAllBtn:'حذف الكل',
		upload:'رفع الملف',
		uploadAgain:'الرفع مرة أخرى',
		uploadExcel:'رفع ملف اكسل',
		beginTime:'وقت البداء',
		endTime:'وقت الانتهاء',
		exportExcel:'تصدير ملف اكسل',
		total:'مشترك',
		secondTotal:'المجموع',
		case:'صندوق',
		time:'الوقت',
		choicePicture:'اختر الصورة',
		city:'المدينة',
		noInfo:'لا يوجد',
		action:'عملية',
		checkFailedReason:'التحقق من سبب الفشل',
		reviewRemark:'ملاحظات',
		
		// 共用 表格列信息
		orderInfo:'معلومات الطلب',
		orderList:'لائحة الطلبات',
		orderDeList:'قائمة تفاصيل الطلب',
		clientOrderNoColumn:'رقم طلب العميل',
		platformOrderNoColumn:'رقم طلب الموقع',
		orderNoColumn:'رقم الطلب',
		subOrderNo:'رقم الطلب الفرعي',
		createOrder:'انشاء طلب',
		createAirOrder:'إنشاء طلب الشحن الجوي',
		batchCreateAirOrder:'تقديم طلب بكميات كبيره',
		createSeaOrder:'انشاء طلب شحن بحري',
		modifyOrder:'تعديل الطلب',
		modifyAirOrder:'تعديل طلب الشحن الجوي',
		modifySeaOrder:'تعديل طلب الشحن البحري',
		initTime:'وقت الطلب',
		inboundTime:'وقت الوصول للمستودع',
		outboundTime:'وقت المغادرة',
		createTimeColumn:'وقت الانشاء',
		inventoryTime:'وقت الوصول للمستودع',
		deliveryTime:'وقت المغادرة من المستودع',
		operationColumn:'العملية',
		statusColumn:'الحالة',
		orderType:'نوع الطلب',
		type:'نوع .',
		result:'النتيجة',
		reason:'السبب',
		link:'الرابط',
		photo:'صورة',
		appointmentTime:'موعد تسليم الشحنة',
		batchOperation:'مجموعة العمليات',
		
		actionDetail:'تحقق من التفاصيل',
		actionCheckReason:'تحقق من السبب',
		orderDetails:'تفاصي الطلب',
		actiontrack:'عرض التتبع',
		actionCheckFile:'عرض المرفقات',
		trackingTitle:'تتبع مسار الشحنة فليتان',
		trackLocation:'الموقع',
		actionCheckPOD:'عرض POD',
		actionCheckCarton:'عرض مربع',
		actionCheckProduct:'عرض المنتج',
		actionModify:'تعديل',
		actionModifyDeliveryTime:'تعديل وقت التسسليم',
		actionDownload:'تنزيل',
		faceNoodles:'تنزيل بليصة الشحن',
		awbGenerating:'جاري انشاء البليصة',
		actionDownloading:'جاري التحميل',
		actionDownloadPDF:'تحميل PDF',
		downloadBoxStickers:'تنزيل ملصق الصندوق',
		boxStickersGenerating:'جاري انشاء ملصق الصندوق',
		actionDownloadSkuList:'تحميل القائمة',
		actionDownloadSeaSkuTemplate:'تحميل النموذج الخاص بالشحن البحري',
		downloadTemplate:'تحميل النموذج',
		downloadClearanceFile:'تحميل نموذج التخليص الجمركي',
		newLabelFile:'تغير ملف العلامة التجارية',
		orderTrack:'معلومات تتبع الطلب',
		moreAction:'المزيد من الإجراءات',
		viewMore:'عرض المزيد',
		
		
		//订单常用信息
		packageNo:'رقم الصندوق',
		index:'السجل',
		dlvdPackageNo:'رقم صندوق فليتان',
		length:'الطول',
		breadth:'العرض',
		height:'الارتفاع',
		weight:'الوزن',
		weightKG:'(KG)الون',
		packageAmount:'عدد الصناديق',
		packageWeight:'وزن الحمولة',
		grossWeight:'الوزن الاجمالي',
		retreatWeight:'وزن الطلب المسترجع',
		secondWeight:'الوزن الصافي',
		chargeWeight:'الوزن النهائي الحسابي',
		chargeVolume:'الحجم',
		chargeVolumeCbm:'(CBM)الحجم ',
		chargeVolumeCcm:'(CCM)الحجم ',
		productAmount:'الكمية الإجمالية للبضائع/الصندوق',
		memo:'ملاحظة',
		remark:'ملاحظة',
		consignor:'اسم المرسل',
		consignee:'اسم المستلم',
		contactMan:'الشخص الذي ممكن نتصل عليه',
		contact:'رقم التواصل',
		address:'عنوان التواصل',
		deliveryAddress:'عنوان الاستلام',
		deliveryPincode:'الرمز البريدي',
		payMentMode:'نوع الدفع',
		amountToCollect:'مبلغ الدفع',
		city:'المدينة',
		country:'الدولة',
		deliveryWarehouse:'مستودع التسليم',
		cnConsignorWhHouse:'مستودع الشحن',
		warhouseCode:'رمز المستودع',
		customeWarehouse:'المستودع الخارجي',
		deliveryCountry:'بلد التسليم',
		deliveryType:'نوع التوصيل',
		deliveryMode:'طريقة التوصيل',
		homeDeliveryService:'التوصيل الى باب الباب',
		whSelfPickup:'الاستلام من المستودع',
		selfPickup:'الاستلام بواسطة العميل',
		consigneeTypeOne:'اسم مستلم الشحنة',
		consigneeContact:'رقم هاتف المستلم',
		deliveryDest:'عنوان التسليم',
		fbaNo:'رقم امازون',
		asnNo:'ASN رقم',
		clientDeliveryNo:'رقم مستودع التسليم للعميل',
		deliveryDate:'موعد تسليم الشحنة',
		pickupDate:'موعد استلام الشحنة',
		fleetanAppointment:'الموعد مع فليتان',
		expectedDeliveryQuantity:'كمية الشحنة المتوقع تسليمها في المستودع',
		fbaNoCode:'رقم التتبع لامازون',
		fbaNoCodeLimit:'الرقم التتبع المكون من8  ارقام',
		fbaNoLimit:'رقم امازون المكون من 12 رقم',
		clientAwbNoLimit:' رقم امازون المكون من 20  رقم',
		companyLimit:'الرقم المكون من 5 او 6 ارقام',
		skuCount:'اجمالي عدد القطع ',
		packageCount:'العدد الاجالي للباكج',
		declaredValue:'اجمالي قيمة البضاعة',
		fleetanWarehouse:'مستودع فليتان الخارجي',
		skuStockType:'إضافة منتجات',
		cartonStockType:'إضافة كراتين كاملة',
		carton:'صندوق كامل',
		count:'الكمية',
		batchNo:'رقم الشحنة',
		batch:'الشحنة',
		batchProductCount:'الكمية الفعلية المستلمة',
		productCount:'عدد الباركودات',
		pcsCount:'عدد القطع',
		actualCarton:'Actual Carton',
		uploadFBAPDF:'Upload PDF',
		companyId:'رقم معرف المتجر',
		currency:'العملة',
		
		// 商品信息
		productInfo:'معلومات المنتج',
		clientProductCode:'رمزمنتج العميل',
		productCode:'كود المنتج في النظام',
		productName:"اسم المنتج",
		productCnName:'اسم المنتج بالصيني',
		productEnName:'اسم المنتج بالانجليزي',
		productReviewFlag:'هل تمت المراجعة او لا',
		productReviewed:'تمت المراجعة',
		productUnreviewed:'لم تتم الراجعة',
		productPrice:'سعر المنتج',
		productQuantity:'كمية المخزون',
		orderQuantity:'كمية الطلبات',
		appointmentQuantity:'عدد الحجوزات',
		productLength:'طول المنتج',
		productBreadth:'عرض المنتج',
		productHeight:'ارتفاع المنتج',
		productSpec:'المواصفات والموديل',
		unit:'الوحده',
		barcode:'رمز الباركود',
		
		
		skuFile:'ملف البيان',
		uploadFileName:'تحميل المرفق',
		uploadSkuFileName:'تحميل القائمة',
		fileLimitExcel:'xls,xlsx فقط يمكنك تحميل ملفات بصيغة',
		fbxFileTips:'FBXل PDF حمل المرفق بصيغة',
		labelFileTips:'上传送仓箱唛-只能上传PDF文件',
		deliveryFileTips:'送仓文件-只能上传PDF文件',
		barcodeFileTips:'客户二维码和仓库二维码-只能上传PDF文件',
		newLabelFileTips:'上传换标文件-只能上传PDF文件',
		newLabelFileNolimitTips:'上传换标文件',
		pdfFileTips:'PDF تحميل مرفقات بصيغة',
		skuFileTips:'تحميل مرفقات الطلب',
		sortSkuFileTips:'xls/xlsxتحميل المرفقات من نوع',
		
		numberAndLetterLimit:' يمكن ااستخدام الأرقام الأحرف الانجليزية',
		numberLimit:'-_ يمكن استخدام الأرقام الأحرف الانجليزية',
		numberToFixedOneLimit:'ادخل رقم مكون من رقم واحد',
		numberToFixedTwoLimit:'ادخل رقم مكون من رقمين',
		numberToFixedThreeLimit:'أدخل ثلاثة أرقام',
		positiveIntegerLimit:'فقط يمكن ادخال اعداد صحيحة موجبة',

		FBATips:'رقم FBA مكون من 12 رقمًا',
		FBAExample:'FBA15G95TKZV مثال',
		
		productionStatusOne:'تم التسجيل',
		productionStatusTwo:'تخصيص موقع التخزين',
		productionStatusThree:'موعد دخول المستودع',
		productionStatusFour:'تم دخول المستودع',
		
		// RTO 
		destory:'اتلاف',
		destorying:'جاري الاتلاف',
		destoryCompleted:'تم الاتلاف',
		inventory:'التخزين',
		inventorying:'جاري التخزين',
		inventoryCompleted:'تم التخزين',
		transfer:'تحويل الشحنة',
		skuStock:'تخزين المنتجات',
		waitForOrder:'في انتظار تعليمات التشغيل',
		processing:'جاري العملية',
		completed:'تمت العملية',
		
		
		// 共用 窗口信息
		dialogBtnConfirm:'تاكيد',
		dialogBtnSubmit:'تسليم',
		dialogBtnSubmiting:'جاري التسليم',
		dialogBtnCancel:'الغاء',
		dialogBtnClose:'اغلاق',
		dialogBtnModify:'تغير',
		dialogBtnReset:'إعادة الضبط',

		cancelProductCount:'كمية المرتجع',

		transportType:'طريقة النقل',
		operationTime:'وقت العملية',
		air:'شحن طبران',
		sea:'شحن بحري',
		local:'محلي',
		
	},
	// 系统设置 /menu.vue
	setting:{
		languageType:'الللغة الصينية',
		changePasswordOption:'تغير كلمة السر',
		loginOutOption:'تسجيل خروج',
	},
	// 菜单列表 /menu.vue
	menuItem:{
		homeItem:'الصفحة',
		airOrderItem:{
			name:'طلبات الشحن الجوي',
			b2bOrder:'B2B شحنة',
			b2cOrder:'B2C شحنة',
		},
		seaOrderItem:{
			name:'طلبات الشحن البحري',
			bulkCargoOrder:'النظام البحري',
			
		},
		
		platformOrderItem:{
			name:'ترتيب المنصة',
			aliExpress:'الي اكسبرس',
		},
		chinaWhItem:{
			name:'مستودع الصين',
			b2cOrder:'B2C شحنة',
			b2bOrder:'B2B شحنة',
		},
		ksaWhItem:{
			name:'مستودع السعودية',
			product:'إدارة المنتج',
			batch:'إدارة المنتجات',
			inventory:'إدارة المستودع',
			skuManage:'إدارة السلع',
			cartonManage:'إدارة الصندوق',
			inscanLog:'سجل تخزين المنتج',
			outscanLog:'سجل شحنة المنتج',
			fbxOrder:'FBX طلب',
			b2cOrder:'B2C طلب',
			operationLogs:'سجل العملية',
			inscanLogs:'سجل الوارد',
			outscanLogs:'سجل الصادر',
			inboundLogs:'سجل العرض',
			outboundLogs:'سجل الازالة',
		},
		rtoItem:{
			name:'إدارة المرتجعات',
			rtoB2cOrder:'B2C طلب',
			rtoFbxOrder:'FBX طلب',
			rtoDamage:'بضائع تالفة',
		},
		invoiceManageItem:{
			name:'إدارة الفواتير',
			storageChargeItem:{
				name:'رسوم التخزين',
				
				chargeDetail:'تفاصيل التكاليف',
			}
		},
		trackingItem:'استعلام عن التتبع',
		clientInfoItem:'بيانات العميل',
		templateDownloadItem:'تحميل النموذج',

		// CWH
		cwhInboundListItem:'مستودع واحد',
		cwhInventoryListItem:'قائمة الجرد',
		cwhOutboundListItem:'مستودع واحد',
	},
	//登录页面 /login.vue
	loginPage:{
		loginName_ph:'الرجاء ادخال اسم المستخدم',
		loginPwd_ph:'ادخل كلمة المرور',
		loginErrorInfo:'اسم المستخدم او كلمة المرور غير صحيحة ،يرجاء إعادة إدخالها',
		loginSuccessInfo:'تم تسجيل الدخول',
		loginOtherError:'الرجاء إدخال اسم المستخدم وكلمة المرور الصحيحة',
		loginBtn:'تسجيل الدخول',
		systemPoweredBy:'الدعم الفني',
		welcomeLogin:'مرحبا بكم في تسجيل الدخول',
		sloganTitle_first:'اختر فليتان لتوسيع أعمالك في الشرق الأوسط',
		sloganTitle_last:'Fleetan',
		slogan_content:'مزود خدمات لوجستية متكاملة لحلول التجارة العابرة للحدود في الشرق الأوسط'
	},
	// 首页页面 、home.vue
	homePage:{
		homeTitle:'اخر اعلان',
		homeUpdateTime:'وقت النشر',
	},
	// 修改密码 /B2B/changePass.vue
	changePassPage:{
		changePassTitle:'تغير كلمة السر',
		oldPassword:'كلمة السر القديمة',
		newPassword:'كلمة السر الجديده',
		confirmPassword:'التأكد من كلمة السر',
		confirmPassword_ph:'يرجاء إعادة كتابة كلمة السر الجديده',
		sameOldAndNewPasswordTips:'لا يمكن ان تكون كلمة السر الجديدة نفس كلمة السر',
		diffNewPasswordTips:'لا يطابق مع كلمة السر الجديده',
		newPasswordLimit:' الحد الأدنى للإدخال هو 5 أرقام، والحد الأقصى للإدخال هو 16 رقمًا',
		cancelAndReload:'تم الغاء التعديل يرجاء تسجيل الدخول مرة اخرى',
		nullPasswordTips:'لا يمكن ان تكون كلمة السر القديمة فارغة',
	},
	// 客户信息
	clientInfoPage:{
		pageTitle:'معلومات العميل',
		clientFullName:'الاسم الكامل للعميل',
		clientShortName:'الاسم المختصر للعميل',
		clientEmail:'الايميل',
		clientPrefixNo:'رمز العميل',
		wcServiceAccount:'Fleetan Wechat Service Account',
		clickAndCopy:'Click to copy',
	},
	// 模板下载页面
	mouldListPage:{
		pageTitle:'قائمة تحميل النماذج',
		templateName:'الاسم',
		templateUrl:'تحميل العنوان',
	},
	// 平台订单
	platformOrder:{
		uploadOrderList:'استيراد قائمة الطلبات',
		aliExpressList:'قائمة طلبات علي اكسبرس',
	},
	// 中国仓库 B2C订单页面 /B2COrderList.vue
	chinaWhB2COrderPage:{
		listTabName:'قائمة',
		B2ClistTabName:'B2C قائمة',
		listTabSearchItemAWB_ph:'رقم شحنة طيران فليتان',
		listTabSearchItemPaymentMode_ph:'طريقة الدفع',
		listTabOperationMultipleDownloadAwb:'批量下载面单',
		//表格信息
		listTabTableColumnAwb:'رقم بليصة الشحن الجوي',
		listTabTableColumnImileAwb:'البليصة',
		listTabTableColumnDestCity:'مدينة الوجهة',
		listTabTableColumnPaymentMode:'طريقة الدفع',
		// 表格操作
		listTabTableActionDownloadAwb:'مصق الشحنة',
		downloadNoodles:'تحميل ملصق الشحنه',
		listTabTableActionMessage:'رسالة',
		messageInformation:'معلومات الرسالة',
		listTabTableActionWeight:'تحقق من الوزن',
		squareWeighing:'الوزن',
		// 轨迹查询窗口
		dialogCheckTrackTitle:'تتبع الشحنة',
		dialogCheckTrackNoData:' لا توجد معلومات المسار حتى الآن',
		
		//订单详情窗口
		dialogDetailOrderInfo:'تفاصيل الطلب',
		dialogDetailAmountToCollect:'قيمة البضاعة',
		dialogDetailProductDes:'وصف المنتج',
		dialogDetailConsignorInfo:'اسم المرسل',
		dialogDetailConsigneeInfo:'اسم المستلم',
		dialogDetailProductDetail:'تفاصيل المنتج',
		dialogDetailProduct_productName:'اسم المنتج',
		dialogDetailProduct_productLink:'رابط المنتج',
		dialogDetailProduct_productHscode:'كود المنتج',
		dialogDetailProduct_productSku:'رمز المنتج',
		dialogDetailProduct_productPcs:'الكمية',
		dialogDetailProduct_productPrice:'سعر المنتج',
		dialogDetailProduct_productTaxrate:'نسبة ضريبة المنتج',
		// 留言板窗口
		dialogMessageTitle:'ترك رسالة',
		dialogMessageInputMessage:'الرجاء كتابة محتوى الرسالة',
		dialogMessageSubmit:'ارسال',
		dialogMessageSubmitSuccess:'تم الارسال',
		// 重量信息窗口
		dialogWeightTitle:'معلومات الوزن',
		
		// 导入Tab页
		importTabName:'استيراد الطلب',
		B2CimportTabName:'B2C استيراد الطلب',
		importTabImportINTBtn:'INT استيراد الطلب',
		importTabImportDOMBtn:'DOM استيراد الطلب',
		importTabImportCDOMBtn:'CDOM استيراد الطلب',
		importTabImportFDOMBtn:'FDOM استيراد الطلب',
		importTabDownloadTemplateBtn:'تحميل النموذج',
		downloadINTTemplateBtn:'INT تحميل نموذج',
		downloadFDOMTemplateBtn:'FDOM تحميل نموذج',
		importTabTableColumnImportTime:'وقت الاستيراد',
		importTabTableColumnOperator:'المسؤل على العملية',
		importTabTableColumnOrderCount:'عدد الطلبات',
		importTabTableColumnAction:'العملية',
		importTabTableColumnActionExportOrder:'تفاصيل التصدير',
		importTabExportDataTips:'هل تريد تأكيد تصدير البيانات المحددة؟',
	},
  // 中国仓库 B2B订单页面 /B2BOrderList.vue
	chinaWhB2BOrderPage:{
		pageTitle:'قائمة الطلبات',
		actionCheckPackage:'التحقق من الوزن',
		actionModifyFBA:'FBA تعديل',
		officialCD:'تخليص رسمي',
		unofficialCD:'تخليص غير رسمي',
		customsDeclarationFile:'التخليص الجمركي',
		generated:'تم إنشاء',
		boxes:'مربعات',
		expandAndHide:' انقر فوق توسيع / إخفاء',
		
		//确认重量窗口
		dialogPackageInfoTitle:'معلومات الوزن',
		// 转单小包包裹信息窗口
		dialogPacketInfoTitle:'المعلومات',
		dialogPacketInfoPacketNo:'رقم الطرد',
		dialogPacketInfoLink:'الرابط',
		dialogPacketInfoResult:'نتيجة الرفع',
		customsDeclaration:'التخليص الجمركي',
		customsDeclarationFlag:'هل مع التخليص الجمركي',
		// 添加品名窗口
		dialogAddKindnameTitle:'إضافة اسم المنتج',
		dialogAddKindname:'اسم المنتج',
		dialogAddKindnameSubmitTips:'الرجاء إضافة اسم المنتج بالصيني والانجليزي',
		kindnameDes:'وصف المنتج',
		
		kindnameSelectTips:'الرجاء اختيار منتج شائع',
		// 修改FBA窗口
		dialogModifyFBANewFBAInfo:'FBA معلومات جديده ل',
		dialogModifyFBANewFBATips:'FBX الرجاء تحميل مرفق',
		szDlvdWarehouseAddress:'عنوان مستودع شنتشن فيتان للخدمات اللوجستية',
		szDlvdWarehouseContact:'Zhang Linhe',
		orderReviewTips:' الطلب قيد المراجعة يرجى الانتظار حتى تمر المراجعة قم بتحميل النموذج ولصقه كما هو مطلوب قبل الشحن.',
		orderSkuCountTips:'يجب أن تكون الكمية الإجمالية للمنتجات المشحونة دقيقة، ويتعين على FBA تحديد موعد لتسليم البضائع إلى المستودع.',
		clientOrderPackageAmount:'عدد الصناديق العميل',
		createBoxNoTips:'أدخل الرقم وسيتم إنشاء باركود على عدد الصناديق',
		downloadPdfFailedTips:'يتم إنشاء نموذج الطلب، يرجى تحديث القائمة وتنزيلها مرة أخرى.',
		createClientPackageNoInputFBATips:'يرجى إدخال رقم FBA أولاً، وسيتم إنشاء رقم الصندوق بناءً على رقم FBA',
		createClientPackageNoInputASNTips:'يرجى إدخال رقم ASN أولاً. وسيتم إنشاء رقم الصندوق بناءً على رقم ASN',
		submitOrderProductionTips:'الرجاء تحديد أو إضافة اسم المنتج',
		submitOrderInputPackageNumTips:'يرجى ملء العدد الإجمالي الصحيح للطرود وقيمة البضائع',
		submitOrderInputSkuNumberTips:'يرجى ملء العدد الإجمالي الصحيح لوحدات SKU وقيمة البضائع',
		submitOrderInputClientPackageNumTips:'الرجاء إدخال عدد الصناديق',
		uploadPacketFileDiffNumTips:'عدد الطرود التي في المرفق لا يتوافق مع عدد الطرود التي قمت بتعبئتها، يرجى التحقق من ذلك',
		
		generateBoxStickerTipsPre:'يتم إنشاء ملصقات الصندوق، لكن سوف يأخذ وقت أطول ، يرجاء الانتظار',
		generateBoxStickerTipsNext:'قم بتحديث القائمة بعد  دقائق وقم بتنزيل الملصقات',
		deliveryDateTips:'يرجى تحديد موعد تسليم ، والذي يتوافق مع وقت تسليم المستندات إلى المستودع',
		
		caseAutoFillTips:'تتم تعبئة معلومات رقم الصندو',
		caseInputTips:'检测到箱号信息未填写，请先补充完整',
		Approved:'تمت المراجعة',
		underReview:'لم تتم المراجعة',
		clientOrderNo:'رقم الطلب',
		
		// 海运订单多目的地信息
		destInfo:'معلومات الوجهة',
		seaOrderDestTitle:'معلومات الوجهة للطلب',
		destType:'نوع الوجهة',
		clearAllDestInfoTips:' ستؤدي هذه العملية إلى مسح كافة معلومات الوجهة. هل تريد التأكيد؟',
		seaCartonIndex:'الرقم التسلسلي للصندوق',
		cartonIndexTips:'يرجى تعبئة معلومات مكان التسليم',
		cartonIndexStart:'بداية الرقم التسلسلي',
		cartonIndexEnd:'نهاية الرقم التسلسلي',
		errorCartonIndex:'لا يمكن أن تكون بداية نطاق الرقم التسلسلي  أكبر من النهاية',
		overCartonAmountTips:'لا يمكن أن يتجاوز الرقم التسلسلي للصندوق إجمالي عدد الصناديق في الطلب',
		inputCartonIndexTips:'12,10-9,7,5-1:الرجاء إدخال الرقم التسلسلي للصندوق، على سبيل المثال',
		inputCartonIndexNumTips:'لا يمكن أن تكون بداية نطاق الرقم التسلسلي  أكبر من النهاية',
		repeatCartonTips:'معلومات وجهة الطلب تحتوي بعض الصناديق على عناوين تسليم متعددة، يرجى التحقق منها',
		cartonTotalPcs:'PCS اجمالي',
		cartonTotalPcsTips:'请输入该目的地派送箱包含的总PCS',
		
		seaOrderFailedReason:'سبب فشل المراجعة',
		
		//分批轨迹
		batchTrackingTitle:'تثسيم المعلومات',
		containWoodOrBamboo:'Contain wood or bamboo',
		shipType:'Ship Type',
		normalShip:'Ordinary',
		fastShip:'Fast',
		shipTypeTips:'The price of fast ships is higher than that of Ordinary ships. Please consult the salesperson for specific prices',
		inWhCount:'In WH Carton Count',
		cancelCount:'Cancel Carton Count',
		express:'ساعي',
	},
	// 沙特仓库，商品列表
	ksaWhProductListPage:{
		pageTitle:'قائمة المنتجات',
		exportProductDataBtn:'تصدير معلومات المنتج',
		multipleImportProductDataBtn:'استيراد المنتجات على دفعات',
		dialogProductAddTitle:'إضافة معلومات المنتج',
		dialogProductModifyTitle:'تعديل معلومات المنتج',
		exportAllProductTips:'تصدير معلومات المنتج',
		productUploadSuccessTips:'تم استيراد المنتج بنجاح. يقوم النظام بمراجعة المنتج وتحليله. يرجى الانتظار',
		productUploadSuccessTips_title:'الأشياء التي يجب ملاحظتها عند استيراد النموذج',
		productUploadSuccessTips_opt1:'الصورة اختيارية',
		productUploadSuccessTips_opt2:'يجب الا تتجاوز خلية الجدول',
		productAnalysis:'ملفات المنتج قيد المراجعة',
		productAnalysisFailed:'ملفات المنتج فشل التحقق',
	},
	// 沙特仓库，批次列表
	ksaWhBatchListPage:{
		pageTitle:'قائمة الدفعات',
		orderNoOrBatchNo:'رقم الطلب',
		turnToSkuStock:'عملية تحويل المنتج لادراجه',
		skuStockDes:'إذا كان الطلب يتطلب تنفيذ أمر من مستودعاتنا الخارجية، أو لم يتم تسليم البضائع المرسلة إلى NOON كطلب كامل في وقت واحد، فاختر هذا النوع',
		cartonStockDes:'1.若由于 FBA 库容不足导致下单前无法获悉明确的 FBA 号,2.FBN 下单前无法创建 ASN 号，但是能明确该订单是整个订单一次性派送',
		turnToSkuTipOne:'نصيحة : هذه العملية سوف ترسل الدفعة التالية',
		turnToSkuTipTwo:'الكل',
		turnToSkuTipThree:'سيتم تحويل المنتجات الموضوعة على الرفوف في صناديق كاملة إلى وضعها على الرفوف بواسطة SKU، ولا يتم دعم التحويل الجزئي',
	},
	// 沙特仓库，库存页面
	ksaWhInventoryPage:{
		tabOneTitle:'تحليل',
		tabOnePageTitle:'تحليل البيانات',
		cartonInfo:'معلومات الصناديق',
		currentCartonOrderCount:'كمية الطلبات الحالية في المخزون',
		currentCartonCount:'كمية الصناديق في المخزون',
		skuInfo:'معلومات المنتج',
		currentSkuCount:'كمية SKU الحالية في المخزون',
		currentProductCount:'الكمية الحالية من البضائع في المخزون',
		
		tabTwoTitle:'المنتجات',
		tabTwoPageTitle:' قائمة مخزون المنتجات',
		cartonInventoryList:'قائمة الجرد',
		exportAllSkuData:'تصدير جميع معلومات مخزون SKU الحالي',
		
		tabThreeTitle:'صندوق كامل',
		tabThreePageTitle:'صندوق كامل في قائمة المخزون',
		exportCurrentAllCartonInventoryData:'تصدير جميع معلومات مخزون الصندوق',
		putOnTime:'وقت الاضافة',
		storageTime:'مدة التخزين/أيام',
		
		inventoryList:'قائمة الجرد',
		inWarehouseList:'قائمة المكتبات',
		productInWarehouseList:'قائمة المنتجات',
		clientBatchCode:'رمز العميل دفعة',
		whName:'اسم المستودع',
		locationName:'مكتبة بت',
		inventoryAmount:'عدد المكتبات',
		totalSku:'مجموع السلع',
		totalPcs:'مجموع عدد القطع',
		totalSkuVolume:'مجموع حجم',
		totalOrder:'النظام العام',
		totalCarton:'مجموع عدد الصناديق',
		totalCartonVolume:'مجموع حجم',
	},
	// 沙特仓库 商品入库记录列表
	ksaWhInscanLogs:{
		pageCartonTitle:'قائمة سجلات تخزين الصناديق',
		pageCartonTitle2:'قائمة ادراج وتخزين الصناديق',
	},
	// 沙特仓库 商品出库记录列表
	ksaWhOutscanLogs:{
		pageCartonTitle:'قائمة سجلات الشحنات الصادره',
		pageCartonTitle2:'قائمة الصناديق المزالة',
	},
	// 沙特仓库 上架日志
	ksaWhInboundLogs:{
		tabOne:'Sku',
		tabOneTitle:'قائمة سجلات تخزين المنتجات',
		tabOneTitle2:'قائمة ادراج المنتجات',
		inboundTime:'تاريخ ادراج المنتجات',
	},
	// 沙特仓库 下架日志
	ksaWhOutboundLogs:{
		tabOne:'Sku',
		tabOneTitle:'قائمة سجل المنتجات الصادرة',
		tabOneTitle2:'قائمة سجل المنتجات المزالة',
		outboundTime:'وقت الازالة',
	},
	
	// 沙特仓库 FBX订单列表页面
	ksaWhFBXOrderPage:{
		multipleImportProduct:'استيراد المنتجات بالجملة الى المستودع للتسليم',
		inputCorrectQuantity:'الرجاء ادخال الرقم الصحيح',
		newLabelCode:'استبدال علامة المنتج',
		newLabelQuantity:'كمية المنتجات المطلوب استبدال العلامة',
		newSku:'جديد Sku رمز',
		newSkuCount:'كمية المنتجات المطلوب استبدال العلامة',
		cancelNumber:'الكمية الملغية',
		newLabelFileInfo:'ملف استبدال العلامات',
		createOrderInfo:'إضافة معلومات الطلب',
		modifyOrderInfo:'تعديل معلومات الطلب',
		submitDeliveryTimeTips:'是否确认该订单送仓时间为',
		checkProductTips:'هل تؤكد أن وقت تسليم هذا الطلب هو',
		deleteOrderTips:'هل تريد حذف الطلب',
		uploadPreTips:'يرجاء التحميل',
		selectProductTips:'يرجاء اختيار المنتج',
		overOrderCountTips:'لا يمكن أن تتجاوز كمية الطلب المدخلة كمية مخزون المنتج',
		orderCountDiffTips:'تم اكتشاف أن الكمية الإجمالية للبضائع المطلوبة غير متوافقة مع كمية التسليم المتوقعة',
		orderCountTips:'اجمالي كمية الطلبات',
		confirmToCreateOrderTips:'يرجى تأكيد ما إذا كنت تريد الاستمرار في إنشاء الطلب',
		checkProductErrorTips:'لم يتم الكشف عن معلومات المنتج	',
		urgentOrderTip:'هذا الطلب هو أمر مستعجل وسيتم فرض رسوم مستعجلة',
	},
	// RTO B2C /RTO/RtoBind.vue
	rtoB2COrderPage:{
		tabOne:'قيد الانتظار',
		tabOnePageTitle:'RTO B2C قائمة',
		tabTwo:'تمت المهمة',
		
		multipleTransfer:'تحويل الطلب',
		multipleInventory:'ادراج الكمية',
		downloadTransferTemplate:'تحميل قالب تحويل الطلب',
		multipleDestory:'تدمير الكمية',
		remainingDay:'الوقت المتبقي للعملية/يوم',
		operationTips:'إذا تجاوز وقت التخزين 30 يومًا، فسيقوم النظام تلقائيًا بتلف الطلب الحالي',
		operationOverTime:'تجاوز الوقت لقد تم تلف البضاعة',
		packetTransfer:'طلب نقل الطرود الصغيرة',
		confirmDestoryOrder:'تأكيد من اتلاف الطلبات',
		confirmMultipleDestoryOrder:'تأكيد من اتلاف الطلبية',
		confirmInventoryOrder:'تأكيد من تنفيذ عملية الإدراج؟',
		transferInfo:'معلومات تحويل الطلب',
		originOrderInfo:'معلومات الطلب',
		confirmToTransferOrder:' تأكيد من اجراء عملية أمر النقل؟',
	},
	// RTO FBX /RTO/RtoFbx.vue
	rtoFbxOrderPage:{
		tabOne:'منتج مسموح',
		tabOnePageTitle:'FBX قائمة المنتجات المسموحة لـ',
		tabTwo:'منتجات غير مسموحة',
		tabTwoPageTitle:'غير مسموحة FBX قائمة منتجات',
		tabThree:'قائمة بالاشياء المكتملة',
		
		rtoCartonNo:'رقم الصندوق المسترجع',
		qcFailedProductInfo:'معلومات المنتج غير المسموحة',
		skuLabelDamage:'ملصق البضاعة التالفة',
		changeSku:'تغيير العلامة التي على البضاعة',
		packageDamage:'التغليف متضرر',
		changeOrigin:'استبدال الغلاف الاصلي',
		changePO:'تغير حقيبة الطرد',
		productDamage:'المنتج تالف',
		label:'الملصق',
		uploadLabel:'رفع الملصق',
		downloadLabel:'تحميل الملصقات',
		
		
		crossTime:'وقت البداء',
		instructionTime:'وقت تنفيذ العملية',
		completeTime:'وقت انتهاء العملية',
		crossCheck:'تفريغ وفحص البضائع',
		transferWh:'نقل المخزون',
		unpackingList:'قائمة التفريغ',
		checkPhoto:'عرض الصورة',
		multipleTransferWh:'نقل الشحنة',
		multipleCrossCheck:'التفريغ والتفتيش',
		multipleStockSku:'عملية ادراج المنتجات',
		transferWhInfo:'معلومات مقل المخزون',
		crossCheckSkuInfo:'معلومات فحص وتوثيق فتح الصناديق',
		qcQualified:'QC الموافقة على',
		qcUnQualified:'QC عدم الموافقة على',
		stockAll:'تخزين وادراج كل البضائع',
		destoryAll:'اتلاف كل البضائع',
		operationType:'نوع العملية',
		crossCheckCompleted:'تم الانتهاء من الفحص، في انتظار التعليمات',
		crossCheckNewTask:'في انتظار أن يقوم المستودع بتنفيذ عملية أمر التفريغ',
		crossCheckAllCompleted:'اكتملت المعالجة  لعملية التفريغ',
		crossCheckTips:' ما إذا كان سيتم إجراء عمليات التفريغ والتفتيش',
		checkAllSkuSelected:'SKU الرجاء التأكد من أنه تم تحديد جميع وحدات',
		qcFailedSkuTips:'غير مؤهل لمعايير ضبط الجودة SKU الكمية وال',
		multipleInventoryTips:'المحددة SKU تأكيد تنفيذ عملية الرفع (الشحنة) لجميع صناديق', 
		
		inventoryTips:'غير المؤهلة لمراقبة الجودة على الرفوف في المخزون التالف SKU المؤهلة لمراقبة الجودة على الرفوف في المخزون العادي، وسيتم وضع SKU غير المؤهلة لمراقبة الجودة إلى عرض الصور قبل وضعها على ادراجها في المخزن. سيتم وضع وحدات SKU تحتاج وحدات', 
		hasQcFailedRowTips:'غير مؤهلة بواسطة مراقبة الجودة. لا يمكن تنفيذ عملية الادراج للمخزون الدُفعة. يرجى إجراء عملية التدمير أو الرف في قائمة التفريغ SKU أو أن هناك SKU لم يتم تحميل',
		isCompletedCrossCheckRowTips:'يرجى تحديد بيانات صندوق التي لم تكتمل لتفريغها وفحصها',
		submitQcPassedDataTips:'يرجى التحقق من معلومات كل منتج',
		submitQcFailedDataTips:'يرجى التحقق من معلومات كل منتج . تتطلب بعض العمليات تحميل صور ملصقات جديدة',
		checkStatusTips:'请勾选等待指令箱数据',
		
		selectStatusTips:'الرجاء تحديد حالة المعالجة',
		crossCheckTimeStartTips:'بداية وقت الانتهاء من التفريغ',
		crossCheckTimeEndTips:'الموعد النهائي لاستكمال التفريغ',
		instructionTimeStartTips:'وقت بداية إصدار التعليمات',
		instructionTimeEndTips:'الموعد النهائي لإصدار التعليمات',
		
	},
	// RTO Damage /RTO/RtoDamage.vue
	rtoDamagePage:{
		pageTitle:'قائمة البضائع التالفة',
	},
	//轨迹查询
	trackQuery:{
		trackTitle:'استعلام المسار',
		trackContent:'أدخل رقم الطلب',
		trackButton:'استعلام',
		NotExist:'رقم الطلب الذي تم إدخاله غير موجود',
		messageImport:'الرجاء إدخال رقم الطلب'
	},
	//B2B状态
	B2BStatus:{
		creating:'创建中',
		init:'تم انشاء الطلب',
		warehousing:'تم دخولها المستودع',
		cnReceive:'تم الانتهاء من التخزين',
		cnPartialShipment:'شحن الشحنة على دفعات',
		cnOutscan:'تم الشحن',
		cnOutscanExceptionPart:'شحنة جزئية',
		cnTransitExceptionCheck:'التفتيش عبر الموانئ',
		cnAir:'تم الطيران',
		cnTransitException:'مرت عبر الميناء',
		cnTransitExceptionOr:'العبور الجزئي',
		cnAirExceptionDelay:'تم تأجيل الرحلة',
		cnAirExceptionCancel:'تم إلغاء الرحلة',
		airArrived:'تم الوصول للميناء',
		customsClearanceCompLated:'تم تخليص الجمارك',
		customsClearanceComplatedExceptionCheck:'استثناء التخليص الجمركي: التفتيش',
		customsClearanceComplatedExceptionDeduction:'استثناء التخليص الجمركي: البضائع المحتجزة',
		overseasArrived:'وصل إلى مستودع في الخارج',
		ksaInscan:'وصل إلى مستودع في الخارج',
		cartonToSku:'نقل المنتج للتخزين',
		ksaReadyForDispatch:'الاستعداد للتوصيل',
		delivering:'جاري التسسليم',
		deliveryFailed:'فشل التسليم',
		partialreceipt:'استلام جزئي',
		successfulDelivery:'تم الاستلام',
		signingFailed:'فشل الاستلام',
		cancelOrder:'الغاء الطلب',
		transferThirdpartyDelivery:'نقل الشحنة للطرف الثالث للتسليم'
	},
	//B2C状态
	B2CStatus:{
		returned:'تم الاسترجاع',
		delivered:'تم الاستلام',
		pending:'جاري المعالجة'
	},
	//B2B订单
	B2BOrder:{
		//table页
		airTransportOrder:'طلبات الشحن الجوي',
		airTransportOrderList:'قائمة طلبات الشحن الجوي',
		oceanShippingOrder:'طلبات الشحن البحري',
		oceanShippingOrderList:'قائمة طلبات الشحن',
		customerAwbNo:'رقم مستودع العميل',
		deCountry:'بلد الوجهة',
		deType:'نوع الخدمة',
		goodCnDesc:'وصف المنتج باللغة الصينية',
		goodEnDesc:'وصف المنتج باللغة الإنجليزية',
		cnConsignorWh:'مستودع الشحن المحلي',
		goodType:'نوع البضائع',
		examineStatus:'حالة المراجعة',
		cartonInfo:'معلومات الصندوق',
		orderNoRepeat:'رقم طلب العميل مكرر',
		succeededOceanOrder:'بنجاح B2B تم تعديل أمر الشحن', 
		addOceanOrder:'بنجاح B2B تمت إضافة أمر الشحن', 
	},
	
	
	// CWH 
	// 入库单
	cwhInboundList:{
		title:'Inbound List',
		inboundNo:'Inbound No',
		createTitle:'Create Inbound List',
		createdStatus:'Created',
		inboundingStatus:'Inbounding',
		inboundedStatus:'Inbounded',
		pendingOutboundStatus:'Pending Outbound',
		outboundingStatus:'Outbounding',
		outboundedStatus:'Outbounded',
		downloadInboundList:'Download List',
		checkCartonTitle:'Inbound List Carton',
	},
	// 库存列表
	cwhInventoryList:{
		title:'Inventory Carton List',
	},
	// 出库单列表
	cwhOutboundList:{
		title:'Outbound List',
		createTitle:'Create Outbound List',
		refuseReason:'Refuse Reason',
		refuseOutbound:'Refuse Outbound',
		OutboundNo:'Outbound No',
		downloadOutboundList:'Download List',
		checkCartonTitle:'Outbound List Carton',
		selectCartonTips:'Please Select Outbound Carton Data',
		
	},
	// 账单管理-仓储费
	storageCharge:{
		title:'تفاصيل رسوم التخزين',
		freeStorageDay:'أيام مجانا',
		startChargeDate:'تاريخ بدء الشحن',
		chargeDate:'تاريخ الشحن',
		chargeDay:'أيام الفواتير',
		unitPrice:'سعر الوحدة',
		totalPrice:'مبلغ الفواتير',
	}
	
}
export default zh;